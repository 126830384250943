import {ID} from '@datorama/akita';

export enum Role {
    administrateur = 'administrateur',
    testeur = 'testeur',
    puppeteer = 'puppeteer',
    firmwareTesteur = 'firmwareTesteur',
    // ---------------------
    kine = 'kine',
    sportif = 'sportif',
    therapistes = 'therapistes',
    partenaire = 'partenaire',
}

export interface User {
    id: ID;
    uid: string;
    idUserFirebase: string;
    urlPhoto: string;
    nom: string;
    prenom: string;
    email: string;
    emailVerified: boolean;
    verified: boolean;
    metier: string;
    specialisation: string;
    ville: string;
    roles: Array<Role>;
}

export function createUser(params: Partial<User>) {
    return {} as User;
}
